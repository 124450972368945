import layout from '@/layout'
export default {
  path: '/word',
  component: layout,
  name: 'word',
  meta: {
    title: '单词管理'
  },
  children: [
    {
      path: '/user-word',
      component: () => import('@/views/word/user/index'),
      name: 'user-word',
      meta: {
        title: '用户词库'
      }
    },
    {
      path: '/official-word',
      component: () => import('@/views/word/official/index'),
      name: 'official-word',
      meta: {
        title: '官方词库'
      }
    },
    {
      path: '/official-word/list/:id',
      component: () => import('@/views/word/official/list'),
      name: 'official-word-list',
      meta: {
        title: '官方词库单词列表'
      }
    }
  ]
}
