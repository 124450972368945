import { ref, h } from 'vue'
import { RouterLink } from 'vue-router'
import { NIcon } from 'naive-ui'
import { HomeOutline as HomeIcon } from '@vicons/ionicons5'
import { UserRegular } from '@vicons/fa'
import {
  AdminPanelSettingsOutlined,
  ModelTrainingSharp,
  GolfCourseFilled,
  PermDataSettingOutlined,
  SupervisedUserCircleOutlined,
  BookOutlined,
  BookmarkAddedOutlined,
  FeaturedVideoOutlined,
  AltRouteFilled,
  SupervisorAccountTwotone,
  AddLinkFilled,
  ArticleRound
  // ArticleOutlined,
  // CommentOutlined,
  // QuestionMarkOutlined,
  // QuestionAnswerOutlined
} from '@vicons/material'

const renderIcon = (icon) => {
  return () => h(NIcon, null, { default: () => h(icon) })
}
export const menuOptions = ref([
  {
    label: () =>
      h(
        RouterLink,
        {
          to: {
            name: '首页'
          }
        },
        { default: () => '首页' }
      ),
    key: 'go-back-home',
    icon: renderIcon(HomeIcon)
  },
  {
    label: '用户',
    key: 'user-admin',
    icon: renderIcon(UserRegular),
    children: [
      {
        label: () =>
          h(
            RouterLink,
            {
              to: {
                name: 'admin'
              }
            },
            { default: () => '管理员' }
          ),
        key: 'admins',
        icon: renderIcon(AdminPanelSettingsOutlined)
      },
      {
        label: () =>
          h(
            RouterLink,
            {
              to: {
                name: 'user'
              }
            },
            { default: () => '普通用户' }
          ),
        key: 'users',
        icon: renderIcon(SupervisedUserCircleOutlined)
      }
    ]
  },
  {
    label: '词库',
    key: 'words',
    icon: renderIcon(GolfCourseFilled),
    children: [
      {
        label: () =>
          h(
            RouterLink,
            {
              to: {
                name: 'user-word'
              }
            },
            { default: () => '用户词库' }
          ),
        key: 'user-words-list',
        icon: renderIcon(BookmarkAddedOutlined)
      },
      {
        label: () =>
          h(
            RouterLink,
            {
              to: {
                name: 'official-word'
              }
            },
            { default: () => '官方词库' }
          ),
        key: 'official-words-list',
        icon: renderIcon(BookOutlined)
      }
    ]
  },
  {
    label: '题库',
    key: 'train',
    icon: renderIcon(ModelTrainingSharp),
    children: [
      {
        label: () =>
          h(
            RouterLink,
            {
              to: {
                name: 'train-word'
              }
            },
            { default: () => '单词测评' }
          ),
        key: 'train-word',
        icon: renderIcon(FeaturedVideoOutlined)
      }
    ]
  },
  {
    label: '运营',
    key: 'operate',
    icon: renderIcon(PermDataSettingOutlined),
    children: [
      {
        label: () =>
          h(
            RouterLink,
            {
              to: {
                name: 'order-list'
              }
            },
            { default: () => '订单管理' }
          ),
        key: 'order-list',
        icon: renderIcon(AltRouteFilled)
      }
      // {
      //   label: () =>
      //     h(
      //       RouterLink,
      //       {
      //         to: {
      //           name: 'share-list'
      //         }
      //       },
      //       { default: () => '分享管理' }
      //     ),
      //   key: 'share-list',
      //   icon: renderIcon(SupervisorAccountTwotone)
      // }
    ]
  },
  {
    label: '精选',
    key: 'featured-articles',
    icon: renderIcon(AddLinkFilled),
    children: [
      {
        label: () =>
          h(
            RouterLink,
            {
              to: {
                name: 'development'
              }
            },
            { default: () => '程序圈' }
          ),
        key: 'development',
        icon: renderIcon(ArticleRound)
      },
      {
        label: () =>
          h(
            RouterLink,
            {
              to: {
                name: 'english'
              }
            },
            { default: () => '英语圈' }
          ),
        key: 'english',
        icon: renderIcon(SupervisorAccountTwotone)
      }
    ]
  }
])
