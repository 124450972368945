import layout from '@/layout'
export default {
  path: '/article',
  component: layout,
  name: 'article',
  redirect: '/article/list',
  meta: {
    title: '精选文章'
  },
  children: [
    {
      path: '/article/development',
      component: () => import('@/views/article/development'),
      name: 'development',
      meta: {
        title: '开发圈'
      }
    },
    {
      path: '/article/english',
      component: () => import('@/views/article/english'),
      name: 'english',
      meta: {
        title: '英语圈'
      }
    },
    {
      path: '/article/category',
      component: () => import('@/views/article/category'),
      name: 'article-category',
      meta: {
        title: '文章分类'
      }
    },
    {
      path: '/article/add',
      component: () => import('@/views/article/addArticle'),
      name: 'article-add',
      meta: {
        title: '添加文章'
      }
    },
    {
      path: '/article/edit/:id',
      component: () => import('@/views/article/editArticle'),
      name: 'article-edit',
      meta: {
        title: '编辑文章'
      }
    },
    {
      path: '/article/detail/:id',
      component: () => import('@/views/article/detailArticle'),
      name: 'article-detail',
      meta: {
        title: '预览文章'
      }
    }
  ]
}
