import layout from '@/layout'
export default {
  path: '/train',
  component: layout,
  name: 'train',
  meta: {
    title: '练习'
  },
  children: [
    {
      path: '/train/word',
      component: () => import('@/views/train/word/index'),
      name: 'train-word',
      meta: {
        title: '单词'
      }
    },
    {
      path: '/train/word/list/:id',
      component: () => import('@/views/train/word/list'),
      name: 'train-word-list',
      meta: {
        title: '单词列表'
      }
    },
    {
      path: '/train/word/generate/:id',
      component: () => import('@/views/train/word/generate'),
      name: 'train-word-generate',
      meta: {
        title: '生成试题'
      }
    },
    {
      path: '/train/word/level/:id',
      component: () => import('@/views/train/word/level'),
      name: 'train-word-level',
      meta: {
        title: '单词关卡'
      }
    }
  ]
}
