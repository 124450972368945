import layout from '@/layout'
export default {
  path: '/operate',
  component: layout,
  name: 'operate',
  meta: {
    title: '运营'
  },
  redirect: '/operate/order',
  children: [
    {
      path: '/operate/order',
      component: () => import('@/views/operate/order/index.vue'),
      name: 'order-list',
      meta: {
        title: '订单管理'
      }
    },
    {
      path: '/operate/share',
      component: () => import('@/views/operate/share/index.vue'),
      name: 'share-list',
      meta: {
        title: '分享管理'
      }
    }
  ]
}
